import React from "react"
import Layout from "../layouts/default"
import Wrapper from "../wrappers"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql } from "gatsby"

const Container = styled.div`
  padding: 20px;
  margin: 50px auto;
  text-align: center;
  @media only screen and (max-width: 786px) {
    margin: 20px auto;
  }
`

const Images = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: 40px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  /* height: 400px; */
`

const Qoute = styled.blockquote`
  border-left: 5px solid ${props => props.theme.primaryColor};
  margin: 40px auto;
  background: #e3e3e3;
  padding: 10px 20px;
  background: #fff;
  border-radius: 5px;
  text-align: right;
  @media only screen and (max-width: 786px) {
    font-size: 11px;
    margin: 20px auto;
  }
`

const Heading = styled.div`
  font-size: 35px;
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
  margin: 20px auto;
  @media only screen and (max-width: 786px) {
    font-size: 25px;
  }
`

const FadedHeading = styled.div`
  margin: 10px auto;
  text-align: left;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  grid-gap: 15px;
  .text {
    text-transform: uppercase;
    color: grey;
    font-size: 35px;
    font-weight: bold;
    opacity: 0.2;
  }
  @media only screen and (max-width: 786px) {
    grid-template-columns: 50px 1fr;
    .text {
      text-transform: uppercase;
      color: grey;
      font-size: 25px;
      font-weight: bold;
      opacity: 0.2;
    }
  }
`

const TextContent = styled.div`
  text-align: left;
  padding: 10px 20px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  p {
    font-size: 17px;
    color: #36454f;
  }
  @media only screen and (max-width: 786px) {
    p {
      font-size: 12px;
    }
  }
`
const WhoAmI = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
  grid-gap: 10px;
  @media only screen and (max-width: 786px) {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }

`

const HeroContent = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  color: GREY;
  font-weight: bold;
  text-align: left;
  padding: 20px;
  span {
    color: ${props => props.theme.primaryColor};
  }
`

export default ({ data }) => {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <Heading>Well, hello there. Who are you?</Heading>
          <WhoAmI>
            <Img fluid={data.roster.childImageSharp.fluid} 
            alt="Fifa 20 Card Ashish Ajimal"
            />
            <HeroContent>
              <div>
                <span>Frontend Engineer</span> from Delhi, India
              </div>
              <p>
                Working with startups to build products from ground up and
                enabling teams to deliver fast and stress less.
              </p>
            </HeroContent>
          </WhoAmI>

          <FadedHeading>
            <Img fluid={data.js.childImageSharp.fluid} alt="JavaScript"/>
            <div className="text">Work Time</div>
          </FadedHeading>

          <Qoute>
            "Well, As The Philosopher Jagger Once Said, 'You Can't Always Get
            What You Want.'"
            <br />- Gregory House, House MD
          </Qoute>

          <TextContent>
            <p>
              2+ Years of Experience in Web Development, working with Javascript
              and React Eco-system to build for all platforms.
            </p>
            <p>
              Functional Programming Patterns to build re-usable and highly
              functioning UIs and tools.
            </p>
          </TextContent>

          <FadedHeading>
            <Img fluid={data.controller.childImageSharp.fluid} alt="xbox one"/>
            <div className="text">Play Time</div>
          </FadedHeading>

          <TextContent>
            <p>
              I play <strong>FIFA</strong> with friends (or mostly with
              Professional AI).
            </p>
            <p>
              I am not that bad at <strong>Call of Duty</strong> but its hard to
              aim with Controller over Keyboard and Mouse
            </p>
          </TextContent>

          <FadedHeading>
            <Img fluid={data.jameson.childImageSharp.fluid} alt='Jameson Irish Whiskey'/>
            <div className="text">Party Time</div>
          </FadedHeading>
          <TextContent>
            <p>
              I like <strong>Sprint Coding</strong> the prototype while drinking
              in 24 Hours. And Eventually Fix Grammar Issues later on.
            </p>
            <p>Enjoy Responsibly</p>
          </TextContent>
          <Qoute>
            "I drink to make other people more interesting."
            <br />- Ernest Hemingway
          </Qoute>
          <Images>
            <Img fluid={data.file.childImageSharp.fluid} alt='ProllyDrunk Logo'/>
            <Img fluid={data.drunk.childImageSharp.fluid} alt='Ashish Ajimal' />
          </Images>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 300
          maxHeight: 300
          traceSVG: {
            color: "#D61A3C"
            turnPolicy: TURNPOLICY_MAJORITY
            blackOnWhite: false
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    drunk: file(relativePath: { eq: "drunkme.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 300
          maxHeight: 300
          traceSVG: {
            color: "#D61A3C"
            turnPolicy: TURNPOLICY_MAJORITY
            blackOnWhite: false
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    controller: file(relativePath: { eq: "controller.png" }) {
      childImageSharp {
        fluid(maxHeight: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    jameson: file(relativePath: { eq: "jameson.png" }) {
      childImageSharp {
        fluid(maxHeight: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    js: file(relativePath: { eq: "js.png" }) {
      childImageSharp {
        fluid(maxHeight: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    roster: file(relativePath: { eq: "roster.png" }) {
      childImageSharp {
        fluid(
          maxHeight: 600,
          traceSVG: {
            color: "#D61A3C"
            turnPolicy: TURNPOLICY_MAJORITY
            blackOnWhite: true
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
